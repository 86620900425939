import { ContentContainer } from '@dayinsure/components';
import { useParams } from 'react-router-dom';
import { formatInUKLocalTime } from '@dayinsure/shared';
import {ContactCards, Loader, PolicySummaryCard} from '../../../components';
import {
  useMotorPolicySummaryQuery, usePerson,
  useQuoteQuery,
  useResetFormState,
} from '../../../hooks';
import { PoliciesPaths } from '../../../routes';
import { PolicyDocuments } from '../../../components/PolicyDocuments';
import { CardWithLogin } from '../../../components/CardWithLogin';
import { hasRACAddOn } from '../../../helpers/policy/hasRACAddOn';

const testId = 'purchase-complete';

export const PurchaseComplete = () => {
  useResetFormState();
  const { quoteId } = useParams<{ quoteId: string }>();
  const { data } = useMotorPolicySummaryQuery(quoteId);
  const { isGuest, isFullAccount } = usePerson();
  const { data: quote } = useQuoteQuery(quoteId, isGuest, isFullAccount);
  const motorQuote = data?.[0];

  if (!data || !quote) {
    return <Loader />;
  }

  return (
    <div className="mb-8 w-full md:mb-16">
      <ContentContainer>
        <h1
          className="mt-8 text-xl text-center md:mt-12 text-main-content-1 lg:mt-15"
          data-testid={`${testId}_title`}
        >
          Purchase complete
        </h1>
        <h2
          className="my-8 text-lg text-center md:mt-12 lg:mt-12 text-main-content-1"
          data-testid={`${testId}_subtitle`}
        >
          Thank you for using us for your car insurance
        </h2>

        {motorQuote && (
          <PolicySummaryCard
            plateNo={motorQuote.vehicleRegistration || ''}
            carDetails={motorQuote.vehicleDescription || ''}
            progress={motorQuote.policyProgress || 0}
            buttonLink={`/${PoliciesPaths.Policies}/${motorQuote?.id}`}
            testId={testId}
            activityDate={`Ends ${formatInUKLocalTime(
              'dd/MM/yyyy',
              motorQuote.endDate
            )} at ${formatInUKLocalTime('HH:mm', motorQuote.endDate)}`}
            className="bg-content-background-prominent"
          />
        )}

        <CardWithLogin testId={`${testId}_info-documents`} />

        {motorQuote?.id && (
          <PolicyDocuments
            className="mt-8 lg:mt-12"
            testId={testId}
            policyId={motorQuote?.id}
          />
        )}
        <ContactCards
          testId={testId}
          className="mt-12"
          displayRAC={hasRACAddOn(quote?.addOns)}
        />
      </ContentContainer>
    </div>
  );
};
