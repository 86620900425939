import { useSiteUnloadEffect } from '@dayinsure/shared';
import jwtDecode from 'jwt-decode';
import { useCallback, useContext, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';
import { OpenAPI as OpenAPIv1 } from '../../../api/v1';
import { OpenAPI as OpenAPIv2 } from '../../../api/v2';
import { PersonContext } from '../../../contexts';
import { useReferrer } from '../../../hooks';
import { QuoteJourneyRoutes } from '../../../routes';
import {useTokenRefreshInterceptor} from "../../../hooks/interceptors/useTokenRefreshInterceptor";

type AuthEventsHandlerProps = {
  children: React.ReactNode;
};

export const AuthEventsHandler = ({ children }: AuthEventsHandlerProps) => {
  const auth = useAuth();
  const location = useLocation();
  const referrer = useReferrer();
  const {
    setPersonAuthDetails,
    setPerson,
    isGuest,
    setIsSignedOff,
    setLogOutIsInProgress,
  } = useContext(PersonContext);
  useTokenRefreshInterceptor();

  // Clear the users token and context
  const clearUser = useCallback(() => {
    OpenAPIv1.TOKEN = undefined;
    OpenAPIv2.TOKEN = undefined;
    if (setPersonAuthDetails && setPerson && setIsSignedOff && setLogOutIsInProgress) {
      setPersonAuthDetails(undefined);
      setPerson(undefined);
      setLogOutIsInProgress(true);
      setIsSignedOff(true);
    }
  }, [setPersonAuthDetails, setPerson, setIsSignedOff, setLogOutIsInProgress]);

  // When the current auth token stored in context changes, add it to the
  // API config
  useEffect(() => {
    if (auth.user?.access_token) {
      const decodedToken = jwtDecode<{
        person_id: string;
        person_type: string;
        ref: string;
      }>(auth.user.access_token);

      const id = decodedToken.person_id;
      const isGuestAccount = decodedToken.person_type === 'Guest';

      if (id && setPersonAuthDetails && setIsSignedOff) {
        setPersonAuthDetails({
          id,
          isGuest: isGuestAccount,
          isFullAccount: !isGuestAccount,
        });
        setIsSignedOff(false);
      }
      OpenAPIv1.TOKEN = auth.user.access_token;
      OpenAPIv2.TOKEN = auth.user.access_token;
    }
  }, [auth.user?.access_token, setPersonAuthDetails, setIsSignedOff]);

  // If the user is ever logged into a different referrer, we want to log them out
  useEffect(() => {
    if (auth.user) {
      const { ten: tenant } = jwtDecode<{
        ten: string;
      }>(auth.user.access_token);

      if (tenant !== referrer.identityProviderName) {
        auth.removeUser();
        clearUser();
      }
    }
  }, [auth, clearUser, referrer]);

  // If the user is unloaded, clear their stored details. This can happen if
  // the guest account is removed, or if we've failed to refresh the token
  // during a 401 interception.
  useEffect(
    () =>
      auth.events.addUserUnloaded(() => {
        clearUser();
      }),
    [auth.events, clearUser]
  );

  // If the token can't be automatically refreshed, and expires
  // attempt a refresh and then restart the silent renew.
  useEffect(
    () =>
      auth.events.addAccessTokenExpired(() => {
        auth.signinSilent().then(() => {
          auth.stopSilentRenew();
          auth.startSilentRenew();
        });
      }),
    [auth]
  );

  // Sign out guest accounts on refresh
  useSiteUnloadEffect(() => {
    if (
      isGuest &&
      !location.pathname.includes(QuoteJourneyRoutes.Payment)
      // TODO: add policy extend payment page in the future
    ) {
      auth.removeUser();
      clearUser();
    }
  });

  return <>{children}</>;
};
